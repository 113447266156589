<template>
  <div>
    <div class="box">
      <div class="container" v-if="Type=='LOGIN'">
        <h1 class="page-title">欢迎登录</h1>
        <el-form :model="input" label-width="80px" class="login-form">
          <el-form-item label="用户名" prop="username">
            <el-input
                v-model="input.username"
                placeholder="请输入用户名"
                prefix-icon="User"
                autocomplete="off" />
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
                v-model="input.password"
                type="password"
                placeholder="请输入密码"
                prefix-icon="Lock"
                autocomplete="off" />
          </el-form-item>
        </el-form>

        <el-button @click="login" class="submit-btn">登 录</el-button>

        <div class="login-options">
          <a @click="getType" class="register-link">没有账号？点击此处注册</a>

          <div class="login-divider">
            <span>其他登录方式</span>
          </div>

          <div class="login-icons">
            <div class="login-icon" @click="qqLoginClick('qq')" id="qqLoginBtn">
              <el-icon class="qq-icon"><Platform /></el-icon>
              <span>QQ登录</span>
            </div>
            <div class="login-icon">
              <a href="/codelogin">
                <el-icon><Histogram /></el-icon>
                <span>扫码登录</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="container" v-if="Type=='REGISTER'">
        <h1 class="page-title">用户注册</h1>
        <el-form class="register-form">
          <el-form-item label="用户名" :label-width="formLabelWidth">
            <el-input
                v-model="register.username"
                placeholder="请输入用户名"
                prefix-icon="User"
                autocomplete="off" />
          </el-form-item>

          <el-form-item label="密码" :label-width="formLabelWidth">
            <el-input
                v-model="register.password"
                type="password"
                placeholder="请输入6-16位密码"
                prefix-icon="Lock"
                @change="v"
                autocomplete="off" />
            <span v-if="error" class="error-message">密码必须为6-16位数字或字母</span>
          </el-form-item>

          <el-form-item label="真实姓名" :label-width="formLabelWidth">
            <el-input
                v-model="register.fullname"
                placeholder="请输入真实姓名"
                prefix-icon="User"
                autocomplete="off" />
          </el-form-item>
        </el-form>

        <el-button @click="regist" class="submit-btn">注 册</el-button>
        <a @click="getType" class="login-link">已有账号？点击此处登录</a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.box {
  height: 100vh;
  width: 100%;
  background: linear-gradient(135deg, #9bd3da, #68c3d4);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.container {
  margin: auto;
  width: 480px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  padding: 40px;
  transition: all 0.3s ease;
  max-height: 90vh;
  overflow-y: auto;
}

.page-title {
  color: #333;
  font-size: 24px;
  text-align: center;
  margin-bottom: 32px;
  font-weight: 600;
}

.login-form, .register-form {
  margin: 0 auto;
  width: 100%;
  max-width: 420px;
}

.el-input {
  --el-input-hover-border-color: #68c3d4;
  --el-input-focus-border-color: #68c3d4;
}

.el-form-item {
  margin-bottom: 20px;
}

.submit-btn {
  width: 80%;
  height: 44px;
  background-color: #68c3d4;
  border: none;
  border-radius: 22px;
  font-size: 16px;
  font-weight: 500;
  margin: 24px auto;
  display: block;
  transition: all 0.3s ease;
}

.submit-btn:hover {
  background-color: #5ab0c0;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(104, 195, 212, 0.3);
}

.login-options {
  text-align: center;
  margin-top: 24px;
}

.register-link, .login-link {
  color: #68c3d4;
  font-size: 14px;
  cursor: pointer;
  transition: color 0.3s;
}

.register-link:hover, .login-link:hover {
  color: #5ab0c0;
}

.login-divider {
  position: relative;
  text-align: center;
  margin: 24px 0;
}

.login-divider::before,
.login-divider::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 35%;
  height: 1px;
  background-color: #eee;
}

.login-divider::before { left: 0; }
.login-divider::after { right: 0; }

.login-divider span {
  background-color: #fff;
  padding: 0 16px;
  color: #999;
  font-size: 14px;
}

.login-icons {
  display: flex;
  justify-content: center;
  gap: 48px;
  margin-top: 24px;
}

.login-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-icon:hover {
  transform: translateY(-3px);
}

.login-icon .el-icon {
  font-size: 24px;
  margin-bottom: 8px;
}

.login-icon span {
  font-size: 13px;
  color: #666;
}

.qq-icon {
  color: #12B7F5;
}

.error-message {
  color: #ff4757;
  font-size: 12px;
  margin-top: 4px;
  display: block;
}

@media (max-width: 480px) {
  .container {
    width: 100%;
    padding: 24px;
  }

  .login-form, .register-form {
    max-width: 100%;
  }

  :deep(.el-form-item__label) {
    width: 70px !important;
  }
}

:deep(.el-form-item) {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

:deep(.el-form-item__label) {
  width: 80px !important;
  padding: 0 12px 0 0 !important;
  line-height: 40px !important;
  font-size: 14px;
}

:deep(.el-form-item__content) {
  flex: 1;
  margin-left: 0 !important;
}

:deep(.el-input) {
  width: 100%;
}
</style>



<script>


import request from "@/utils/request";

import QC from 'qc'

export default {

  // eslint-disable-next-line vue/multi-word-component-names
  name: 'login',
  // 页面加载的时候，做一些事情，在created里面


  data() {
    // 验证码校验

    return {
      captchaUrl: "",
      key: "",
      input: {
        username:'',
        password:'',

      },

      register:{
        username:'',
        password:'',
        fullname:'',
      },
      Type:"LOGIN",
      formLabelWidth:'30%',

      error:false,

    }

  },
  created() {

    var vm = this;
    // 获取验证码
    if (QC.Login.check()) {
      // 该处的openId，accessToken就是后台需要的参数了，后台可以通过这些参数获取临时登录凭证，然后就是自己的逻辑了
      QC.Login.getMe(function (openId, accessToken) {
        if (openId !== undefined) {
          //openId 是用户的唯一标识，也是需要存到数据库的
          console.log(openId)
          console.log('-------------------------------')
          console.log(accessToken)
          //https://graph.qq.com/user/get_user_info?access_token=YOUR_ACCESS_TOKEN&oauth_consumer_key=YOUR_APP_ID&openid=YOUR_OPENID

          request.get("/user/qqlogin?access_token="+accessToken+"&openid="+openId)
              .then(res => {
                if (res.code == "1") {
                  localStorage.setItem("user", JSON.stringify(res.data));

                  vm.$router.push('/LayOut');
                }
                else {
                  vm.$message({
                    message: res.msg,
                    type: 'error'
                  });
                }

              })





        }
      })

    } else {
      console.log('未登录')
    }},

  methods: {
    // QQ 第三方登录
    qqLoginClick () {

      QC.Login.showPopup({
        appId: '102446085',
        redirectURI: 'https://hsq.org.cn/login'
      })

      // 直接弹出授权页面，授权过后跳转到回调页面进行登录处理

    },

    v(){
      let userNameReg = /^(?![0-9]+$)[0-9A-Za-z|a-zA-Z]{6,16}$/;
      if (!userNameReg.test(this.register.password)) {
        this.error=true
        return false;
      }else {
        this.error = false
        return true;
      }
    },
    login() {

      if (this.input.username == '' | this.input.password == '') {
        this.$message({
          message: '账号或密码不能为空',
          type: 'error'
        });
      } else {
        request.post("/user/login" , this.input).then(res => {
          if (res.code == 1) {
            this.$message({
              message: '登录成功',
              type: 'success'
            });


            localStorage.setItem("user", JSON.stringify(res.data));

            this.$router.push('/LayOut');

          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            });


          }
        })
      }
    },
    Ifonline(){
      request.post('/onlineusers/getonline',this.input).then(res=>{
        if(res.code=="1"){
          this.login();

        }else{
          this.$message({
            message:res.msg,
            type:"error"
          })
        }
      })

    },
    getType(){
      if(this.Type=="LOGIN"){
        this.Type="REGISTER"
        this.$message({
          message: '请注册',
          type: 'success'
        });

      }
      else {
        this.Type="LOGIN"
        this.$message({
          message: '请登录',
          type: 'success'
        });

      }
    },

    regist(){

      {
        if (this.register.username == '' | this.register.password == '') {
          this.$message({
            message: '账号或密码不能为空',
            type: 'error'
          });
        } else {
          request.post('/user/regist', this.register).then(res => {
            if (res.code == 1) {
              this.$message({
                message: '注册成功,请登录',
                type: 'success'
              });
              this.register = {},
                  this.Type = "LOGIN"


            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });

            }
          })
        }
      }

    }

  },
  mounted() {

  }
}
</script>
