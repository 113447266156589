import { createRouter, createWebHistory } from 'vue-router'
import User from './components/other/User.vue'

import Img from './components/other/Img.vue'
import LayOut from "@/components/other/Layout.vue";

import Login from "@/components/other/login.vue";
import Message from "@/components/other/Message.vue";


import Read from "@/components/novel/Read.vue";

import ChatGpt from "@/components/other/ChatGpt.vue";
import Write from "@/components/novel/Writenovel.vue";
import Novelchapter from "@/components/novel/Novelchapter.vue";
import Gpt from './components/other/CodeLogin.vue'
import Noveltype from "@/components/novel/Noveltype.vue";

import novels from "@/components/novel/novels.vue";
import Chaptertable from "@/components/novel/chaptertable.vue";
import NovelContent from "@/components/novel/NovelContent.vue";
import Qhome from "@/components/other/Qhome.vue";
import Home from "@/components/other/Home.vue";
import Info from "@/components/other/Info.vue";
import Comment from "@/components/other/Comment.vue";
import CarouselChart from "@/components/other/CarouselChart.vue";
import LogView from "@/components/other/LogView.vue";
import Reply from "@/components/other/Reply.vue";
import NotFoundComponent from "@/components/组件/NotFoundComponent.vue";

const routes = [
    {
        path: '/codelogin',
        name: 'codelogin',
        component: Gpt,

    },

    {
        path: '/qhome',
        name: 'qhome',
        redirect:'/novels',
        component: Qhome,
        children: [
            {
                path: '/novelchapters',
                name: 'novelchapters',
                component: Novelchapter,

            },

            {
                path: '/message',
                name: 'message',
                component: Message
            },
            {
                path: '/img',
                name: 'img',
                component: Img,
            },
            {
                path: '/novels',
                name: 'novels',
                component: novels
            },
            {
                path: '/info',
                name: 'info',
                component: Info
            },
            {
                path: '/write',
                name: 'write',
                component: Write,
            },

        ]

    },

    {
        path: '/LayOut',
        name: 'LayOut',
        component: LayOut,
        redirect:'/home',
        meta: { requiresAdmin: true },
        children: [ {
            path: '/user',
            name: 'user',
            component: User,

        },
            {
                path: '/home',
                name: 'home',
                component: Home
            },




            {
                path: '/gpt',
                name: 'gpt',
                component: ChatGpt
            },

            {
                path: '/read',
                name: 'read',
                component: Read,
            },
            {
                path:'/noveltype',
                name: 'noveltype',
                component: Noveltype

            },


            {
                path: '/comment',
                name: 'Comment',
                component: Comment,
            },
            {
                path: '/chaptertable',
                name: "chaptertable",
                component: Chaptertable
            },
            {
                path: '/carouselChart',
                name: "CarouselChart",
                component: CarouselChart
            },
            {
                path: "/logview",
                name: "LogView",
                component: LogView
            },
            {
                path: '/reply',
                name: 'Reply',
                component: Reply

            }


        ]
    },
    {
        path: '/',
        name: 'index',
        component: Login,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/novelcontent',
        name: 'NovelContent',
        component: NovelContent,
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',

        component: NotFoundComponent
    }


]

const router = createRouter({
    history: createWebHistory(),
    routes
})
//路由守卫，我要所有LayOut路由的children的路由都要判断localStorage是否有user信息，user的权限是否是admin，如果不是admin，就跳转到/home路由
router.beforeEach((to, from, next) => {
    // 检查即将进入的路由是否包含meta.requiresAdmin，并且该路由是Layout的子路由
    if (to.matched.some(record => record.meta.requiresAdmin)) {
        // 检查用户是否为管理员
        const user = JSON.parse(localStorage.getItem('user'));


        if (user.permission !== '管理员') {
            // 如果不是管理员，可以选择重定向到登录页或其他页面
            // 或者显示一个错误消息，阻止跳转

            next('/404'); // 假设有一个/no-access路由用于显示无权访问页面

            return;
        }
    }
    // 如果用户有权限或不需要权限，则继续导航
    next();
});





export default router