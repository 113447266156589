<style scoped>
/* 主容器样式 */
.common-layout {
  position: fixed;
  top: 60px;  /* 为顶部菜单留出空间 */
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: #f5f5f5;
  overflow: hidden;
  z-index: 1;  /* 确保在菜单下方 */
}

/* 侧边栏样式 */
.scrollable-aside {
  width: 250px !important;
  background-color: #fff;
  border-right: 1px solid #e6e6e6;
}

:deep(.el-scrollbar) {
  height: calc(100vh - 60px);  /* 减去顶部菜单的高度 */
}

/* 主聊天区域样式 */
.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);  /* 减去顶部菜单的高度 */
  overflow: hidden;
  position: relative;
}

/* 聊天标题样式 */
.chat-title {
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  font-size: 16px;
  color: #333;
  flex-shrink: 0;
}

/* 消息区域样式 */
.message-area {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #f5f5f5;
}

/* 消息气泡样式 */
.message-bubble {
  margin: 16px 0;
  display: flex;
  flex-direction: column;
}

.p {
  display: inline-block;
  padding: 10px 14px;
  border-radius: 4px;
  max-width: 60%;
  word-wrap: break-word;
  font-size: 14px;
  line-height: 1.5;
}

.sender-message {
  background-color: #95ec95;
  margin-left: auto;
  border-radius: 4px 4px 0 4px;
}

.receiver-message {
  background-color: #ffffff;
  margin-right: auto;
  border-radius: 4px 4px 4px 0;
}

/* 底部工具栏样式 */
.footer-area {
  background-color: #fff;
  border-top: 1px solid #e6e6e6;
  flex-shrink: 0;
}

.toolbar {
  padding: 8px 16px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
}

/* 输入区域样式 */
.input-area {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #fff;
}

.el-input {
  flex: 1;
}

:deep(.el-input__inner) {
  border-radius: 4px;
  height: 40px;
}

.send-button {
  min-width: 80px;
  height: 40px;
  border-radius: 4px;
}

/* 文件上传区域样式 */
.file-upload {
  display: flex;
  align-items: center;
  gap: 16px;
}

/* 文件列表样式 */
.file-list {
  position: absolute;
  bottom: 120px; /* 调整位置在输入框上方 */
  left: 250px; /* 与侧边栏对齐 */
  right: 0;
  padding: 8px 16px;
  background-color: rgba(255, 255, 255, 0.98);
  border-top: 1px solid #e6e6e6;
  max-height: 150px;
  overflow-y: auto;
}

/* 上传按钮样式 */
.upload-options {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
}

:deep(.el-popover) {
  padding: 0;
}

:deep(.el-upload) {
  width: 100%;
}

/* 录音提示样式 */
#startlisten {
  color: #666;
  font-size: 13px;
  margin-left: 8px;
}
</style>

<template>
  <div class="common-layout">
    <!-- 侧边栏 -->
    <el-aside width="250px" class="scrollable-aside">
      <el-scrollbar>
        <el-menu v-for="user in from" :key="user.username">
          <el-menu-item @click="receiveMessages(user.id);value=user.username;">
            <el-badge :value="notread[user.id] || ''" class="item">
              {{user.username}}
            </el-badge>
          </el-menu-item>
        </el-menu>
      </el-scrollbar>
    </el-aside>

    <!-- 主聊天区域 -->
    <div class="chat-container">
      <!-- 聊天标题 -->
      <div class="chat-title">
        {{value ? `与 ${value} 的聊天` : '选择一个联系人开始聊天'}}
      </div>

      <!-- 消息区域 -->
      <div class="message-area" ref="mainContent">
        <div v-for="(msg, index) in messages" :key="index" class="message-bubble">
          <div class="p" :class="msg.sender == user.id ? 'sender-message' : 'receiver-message'">
            <span>{{ msg.message }}</span>

            <el-image v-if="msg.img"
                      :src="'https://hsq.org.cn:8090/hsq/file/'+msg.img"
                      :preview-src-list="getPreviewSrcList(msg.img)"
            />

            <div v-if="msg.file" class="file-item">
              <el-icon>
                <Document />
              </el-icon>
              <span id="fname" @click="getfilename(msg.file,index)">文件</span>
              <el-link type="primary" :href="'https://hsq.org.cn:8090/hsq/file/'+ msg.file">下载</el-link>
            </div>
          </div>
        </div>
      </div>

      <!-- 文件列表 -->
      <div class="file-list" v-if="customFileList.length">
        <div v-for="file in customFileList" :key="file.uid" class="file-item">
          <el-icon>
            <Document />
          </el-icon>
          <span>{{file.type}}: {{ file.name }}</span>
        </div>
      </div>

      <!-- 底部输入区域 -->
      <div class="footer-area">
        <div class="toolbar">
          <div class="file-upload">
            <el-popover placement="top" :width="200">
              <template #reference>
                <el-button>
                  <el-icon>
                    <Plus />
                  </el-icon>
                  添加
                </el-button>
              </template>

              <div class="upload-options">
                <el-upload
                    ref="imgRef"
                    action="https://hsq.org.cn:8090/hsq/file/imgupload"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    :limit="1"
                >
                  <template #default>
                    <el-button>
                      <el-icon>
                        <Picture />
                      </el-icon>
                      <span>图片</span>
                    </el-button>
                  </template>
                </el-upload>

                <el-upload
                    ref="fileRef"
                    action="https://hsq.org.cn:8090/hsq/file/fileupload"
                    :show-file-list="false"
                    :on-success="fileSuccess"
                    :limit="1"
                >
                  <template #default>
                    <el-button>
                      <el-icon>
                        <Document />
                      </el-icon>
                      <span>文件</span>
                    </el-button>
                  </template>
                </el-upload>
              </div>
            </el-popover>
            <span id="startlisten">按住'左Ctrl'开始录音</span>
          </div>
        </div>

        <div class="input-area">
          <el-input
              v-model="receiveuser.message"
              placeholder="输入消息..."
              :maxlength="50"
              @keyup.enter="send"
          />
          <el-button type="primary" @click="send" class="send-button">
            发送
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";


export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Message',

  data() {
    return {
      notread: "",
      from: {},
      value: "",
      messages: [],
      params: {

        pageNum: 1,
        pageSize: 500
      },
      receiveuser: {
        data: "",
        receiver: "",
        message: "",
        sender: "",
        img: "",
        file: ""


      },
      Selectimage: false,
      user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
      web: {},
      customFileList: [],
      filelink: "",
      list: []
    }
  },


  methods: {

    //获取用户
    getusers() {


      request.post("/user/get", this.params).then(res => {
        if (res.code === '1') {

          this.from = res.data.list;
          const requestData = {
            users: this.from,
            user: this.user
          };
          request.post("/msg/g", requestData).then(res => {
                if (res.code === '1')
                  this.notread = res.data;
                console.log(this.notread['hsq'])
              }
          )
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })

    },
    getPreviewSrcList(imgUrl) {
      return ['https://hsq.org.cn:8090/hsq/file/' + imgUrl];
    },
    beforeAvatarUpload(file) {
      const isJPGorPNG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt3M = file.size / 1024 / 1024 < 3;

      if (!isJPGorPNG) {
        this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
        return false;
      }
      if (!isLt3M) {
        this.$message.error('上传头像图片大小不能超过 3MB!');
        return false;
      }

      return true;
    },
    //获取文件名
    getfilename(file, index) {
      const message = this.messages;
      let newIndex = index;
      for (let i = 0; i <= index; i++) {
        if (!message[i].file) {
          newIndex = newIndex - 1;
        }
      }
      setTimeout(() => {
        const onurl = 'https://hsq.org.cn:8090/hsq/file/' + file;
        const fnames = document.querySelectorAll('#fname');
        fetch(onurl)
            .then(response => {

              const contentDisposition = response.headers.get('content-disposition');
              const [, fileName] = contentDisposition.split(';').map(item => item.trim());
              const name = decodeURIComponent(fileName);
              const link = name.substring(name.indexOf("=") + 1);

              fnames[newIndex].textContent = link;
            })
      }, 300)
    },
    //文件超过上传数量
    handleExceed() {
      this.$message.error('上传文件数量不能超过1!');
    },
    //获取历史消息
    receiveMessages(users) {

      this.receiveuser.receiver = users;

      if (this.web.readyState === WebSocket.OPEN) {
        this.web.close()
      }

      const url = "ws://localhost:8090/chat/" + this.user.id + "/" + users;
      var socket = new WebSocket(url)
      const toUser = this.user.id
      const FromUser = users

      this.web = socket;
      this.web.onopen = function () {
        console.log("连接成功");


      };
      this.getcon(toUser, FromUser);

      request.post("/msg/readed?toUser=" + toUser + "&FromUser=" + FromUser)
      this.getusers();
      const mainContent = this.$refs.mainContent;
      this.web.onmessage = () => {

        this.l(); // 调用 l 方法
        this.getusers();
        setTimeout(function () {
          mainContent.scrollTop = mainContent.scrollHeight;
        }, 100);
      };
      this.web.onerror = function () {

      }

      this.web.onclose = function () {
        console.log("WebSocket Connection Closed");
      };

      request.post("/msg/getmessage", this.receiveuser).then(res => {
        if (res.code === '1') {
          this.messages = res.data;
        } else {
          this.receiveuser.receiver = ""
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })


    },
    l() {
      request.post("/msg/getmessage", this.receiveuser).then(res => {
        if (res.code === '1') {
          this.messages = res.data;
        } else {
          this.receiveuser.receiver = ""
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })
    },
    //上传成功
    handleAvatarSuccess(res, file) {
      // 上传成功后，从服务器返回的数据中获取文件 URL
      // 文件上传成功的处理逻辑
      file.type = '图片'
      this.customFileList.push(file);
      this.receiveuser.img = res.data;


    },
    fileSuccess(res, file) {
      this.fileurl = 'https://hsq.org.cn:8090/hsq/file/' + res.data;
      file.type = '文件'
      this.customFileList.push(file);
      this.receiveuser.file = res.data;

    },
    //发送消息
    send() {
      const mainContent = this.$refs.mainContent;
      if (this.receiveuser.message != "") {
        const date = new Date();
        this.receiveuser.data = date.toLocaleString();
        var message = JSON.stringify(this.receiveuser.message);
        this.web.send(message);
        this.$refs.imgRef.clearFiles();
        this.$refs.fileRef.clearFiles();
        request.post("/msg/save", this.receiveuser).then(res => {
          if (res.code === '1') {

            this.$message({
              message: "发送成功",
              type: 'success'
            });
            this.receiveuser.message = "";
            this.receiveuser.img = "";
            this.customFileList = [];
            this.receiveuser.file = "";
            this.receiveMessages(this.receiveuser.receiver)


          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            });
          }
        })
      } else {
        this.$message({
          message: "输入框不能为空",
          type: 'error'
        });
      }
      setTimeout(function () {
        mainContent.scrollTop = mainContent.scrollHeight;
      }, 100);
    },
    chehui(id) {
      request.get("/msg/delete", id).then(res => {
        if (res.code === '1') {

          this.from = res.data.list;
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })

    },
    //录音
    listenstart() {   // 录音部分
      setTimeout(() => {
        const recognition = new window.webkitSpeechRecognition();
        recognition.lang = 'zh-CN';
        // 创建变量来表示是否正在录音
        let isRecording = false;
        const inputElement = document.getElementById('input');
        const listenElement = document.getElementById('startlisten');
// 监听键盘按下事件
        document.addEventListener('keydown', function (event) {
          // 检查按下的键是否为空格键，并且当前不在录音状态

          if (event.code === 'ControlLeft' && !isRecording) {
            setTimeout(() => {
              recognition.start();
            }, 500);
            listenElement.textContent = "正在录音"
            isRecording = true;
          }
        });

// 监听键盘抬起事件
        document.addEventListener('keyup', function (event) {
          // 检查抬起的键是否为空格键，并且当前在录音状态
          if (event.code === 'ControlLeft' && isRecording) {

            recognition.stop();
            listenElement.textContent = "按'左Ctrl'开始录音"
            isRecording = false;
          }
        });

// 监听语音识别结果
        recognition.onresult = (event) => {
          const transcript = event.results[0][0].transcript;
          console.log('识别结果:', transcript);
          // 将识别结果添加到屏幕元素中
          this.receiveuser.message += transcript;
          inputElement.value += transcript;
        };

// 监听语音识别错误
        recognition.onerror = function (event) {
          console.error('语音识别错误:', event.error);
        };

// 停止语音识别
        recognition.onend = function () {
          console.log('语音识别结束');
        };


      }, 1000)
    },
    getcon(toUser, FromUser) {

      this.obj = {
        [toUser]: FromUser
      };

      this.list.push(this.obj);

      // 查找数组中相应的对象并更新值
      const index = this.list.findIndex(item => item[toUser] === FromUser);
      if (index !== -1) {
        this.list[index][toUser] = this.obj[toUser];
      }


    }
  },
  watch: {
    //监听数组中msg.file的变化
    messages: {
      deep: true,
      handler(newVal) {
        newVal.forEach((msg) => {
          if (msg.file != null) {
            setTimeout(() => {
              const fnames = document.querySelectorAll('#fname');
              fnames.forEach((fname) => {
                fname.click();
              });
            }, 400);
          }
        });
      }
    },
  },
  mounted() {
    this.getusers();

    this.receiveuser.sender = this.user.id;
    this.listenstart();
  },

}
</script>